import React from "react";

import "./cssSelectionStyls.css";

const Cssselection = () => {
  return (
    <div>
      <div className="div div1"></div>
      <div className="div div2"></div>
    </div>
  );
};

export default Cssselection;
