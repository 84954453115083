import React, { useState } from "react";
import { Squash as Hamburger } from "hamburger-react";

import "./navBarStyles.css";

const Navbar = () => {
  const toogleOpen = (value) => {
    setOpen(value);
    console.log(value);
  };

  const [isOpen, setOpen] = useState(false);

  return (
    <div className="navBar">
      <div className="button">
        <Hamburger toggled={isOpen} toggle={toogleOpen} size={30}/>
      </div>
    </div>
  );
};

export default Navbar;
