import React from "react";
import Projects from "./tabs/projects/Projects";
import Contact from "./tabs/contact/Contact";
import About from "./tabs/about/About";
import Home from "./home/Home";

import "./App.css";

import Menu from "./menu/Menu";

import { Route, Routes } from "react-router-dom";
import Testing from "./testing/Testing";
import NotFound from "./notFound/NotFound";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/menu" element={<Menu />} />
        <Route path="/projects" element={<Projects />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/testing/*" element={<Testing />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </div>
  );
}

export default App;
