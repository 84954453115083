import React from "react";
import Cssselection from "./cssSelection/CssSelection";
import { Routes, Route, useMatch } from "react-router-dom";
import Svganimation from "./svgAnimation/SvgAnimation";

const Testing = () => {
  let hhh = useMatch();

  console.log(hhh);
  return (
    <div>
      <Routes>
        <Route path="/cssselection" element={<Cssselection />} />
        <Route path="/svg" element={<Svganimation />} />
      </Routes>
    </div>
  );
};

export default Testing;
