import React from "react";

import "./svgAnimationStyles.css";

const Svganimation = () => {
  return (
    <div>
      <svg width="350" height="40">
        <rect y="139.474" className="line" width="290.658" height="11.711" />
      </svg>
    </div>
  );
};

export default Svganimation;
