import React from "react";
import "./menuStyles.css";

import { Link } from "react-router-dom";
import Navbar from "./../navBar/NavBar";

const Menu = ({ clickFunc }) => {
  const menuEls = [
    ["Projects", "projects"],
    ["About", "about"],
    ["Contact", "contact"],
  ];
  return (
    <>
      <Navbar />
      <div className="menuParent">
        <div className="lineAnimation">
          <svg
            width="668"
            height="544"
            viewBox="0 0 668 544"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g id="menuLines">
              <path
                id="left"
                d="M72 332L72 0"
                stroke="#5c5c5c"
                strokeWidth="4"
              />
              <path
                id="top"
                d="M435 61L0 61"
                stroke="#5c5c5c"
                strokeWidth="4"
              />
              <path
                id="right"
                d="M581 544L581 232"
                stroke="#5c5c5c"
                strokeWidth="4"
              />
              <path
                id="bottom"
                d="M668 471H215"
                stroke="#5c5c5c"
                strokeWidth="4"
              />
            </g>
          </svg>
        </div>
        <div className="menuOptions">
          {menuEls.map((e, i) => {
            return (
              <div key={i} className="menuBarEl">
                <Link to={`/${e[1]}`} data-text={e[0]}>
                  {e[0]}
                </Link>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default Menu;
