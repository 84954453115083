import React from "react";

import "./notFound.css";

const NotFound = () => {
  return (
    <div>
      <h1>Oops...</h1>
      <h3>We Couldn't Reach This Page</h3>
      <a href="./">Return to main page...</a>
    </div>
  );
};

export default NotFound;
