import React from "react";

import "./aboutStyles.css";
import Navbar from "./../../navBar/NavBar";

const About = () => {
  return (
    <div>
      <Navbar />
      <h1>About</h1>
    </div>
  );
};

export default About;
