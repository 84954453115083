import React from 'react'
import Navbar from '../navBar/NavBar'

import "./homeStyles.css"

import Monogram from "../../imgs/global/bk_monogram.png";
import CoverPicture from "../../imgs/homePage/cover_picture.jpg";

function Home() {
  return (
    <div id='homeContainer'>
        <Navbar />
        <div id="headContainer">
            <img src={Monogram} alt="Monogram here" id='monogram' />
            <h1 id='headH1'>architecture & constructure</h1>
        </div>
        <img src={CoverPicture} alt="coverPicture" id='coverPicture' />
        <h1 id='watermark'>...UNDER CONSTRUCTION</h1>
        <hr/>
    </div>
  )
}

export default Home