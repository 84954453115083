import React from "react";

import "./contactStyles.css";
import Navbar from "./../../navBar/NavBar";

const Contact = () => {
  return (
    <div>
      <Navbar />
      <h1>Contact</h1>
    </div>
  );
};

export default Contact;
