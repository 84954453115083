import React from "react";

import "./projectsStyles.css";
import Navbar from "./../../navBar/NavBar";

const Projects = () => {
  return (
    <div>
      <Navbar />
      <h1>Projects</h1>
    </div>
  );
};

export default Projects;
